var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _c("strong", [_vm._v(" Bootstrap Navbar ")]),
              _c("div", { staticClass: "card-header-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "card-header-action",
                    attrs: {
                      href: "https://coreui.io/vue/docs/components/navbar",
                      rel: "noreferrer noopener",
                      target: "_blank"
                    }
                  },
                  [_c("small", { staticClass: "text-muted" }, [_vm._v("docs")])]
                )
              ])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { expandable: "md", color: "info" } },
                [
                  _c("CToggler", {
                    attrs: { inNavbar: "" },
                    on: {
                      click: function($event) {
                        _vm.show = !_vm.show
                      }
                    }
                  }),
                  _c("CNavbarBrand", { attrs: { href: "#" } }, [
                    _vm._v("NavBar")
                  ]),
                  _c(
                    "CCollapse",
                    { attrs: { show: _vm.show, navbar: "" } },
                    [
                      _c(
                        "CNavbarNav",
                        [
                          _c("CNavItem", { attrs: { href: "#" } }, [
                            _vm._v("Link")
                          ]),
                          _c(
                            "CNavItem",
                            { attrs: { href: "#", disabled: "" } },
                            [_vm._v("Disabled")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "CNavbarNav",
                        { staticClass: "ml-auto" },
                        [
                          _c(
                            "CForm",
                            { attrs: { inline: "" } },
                            [
                              _c("CInput", {
                                staticClass: "mr-sm-2",
                                attrs: { placeholder: "Search", size: "sm" }
                              }),
                              _c(
                                "CButton",
                                {
                                  staticClass: "my-2 my-sm-0",
                                  attrs: { color: "light", size: "sm" }
                                },
                                [_vm._v(" Search ")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "CDropdown",
                            {
                              attrs: {
                                "toggler-text": "Lang",
                                "in-nav": "",
                                placement: "bottom-end"
                              }
                            },
                            [
                              _c("CDropdownItem", [_vm._v("EN")]),
                              _c("CDropdownItem", [_vm._v("ES")]),
                              _c("CDropdownItem", [_vm._v("RU")]),
                              _c("CDropdownItem", [_vm._v("FA")])
                            ],
                            1
                          ),
                          _c(
                            "CDropdown",
                            {
                              attrs: {
                                "in-nav": "",
                                "toggler-text": "User",
                                placement: "bottom-end"
                              }
                            },
                            [
                              _c("CDropdownItem", [_vm._v("Profile")]),
                              _c("CDropdownItem", [_vm._v("Signout")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Navbar ")]),
              _c("small", [_vm._v("brand")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { color: "faded", light: "" } },
                [
                  _c("CNavbarBrand", { attrs: { href: "#" } }, [
                    _c("img", {
                      staticClass: "d-inline-block align-top",
                      attrs: {
                        src: "https://placekitten.com/g/30/30",
                        alt: "CoreuiVue"
                      }
                    }),
                    _vm._v(" CoreuiVue ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Navbar ")]),
              _c("small", [_vm._v("text")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { toggleable: "sm", light: "", color: "light" } },
                [
                  _c("CToggler", {
                    attrs: { inNavbar: "" },
                    on: {
                      click: function($event) {
                        _vm.navbarText = !_vm.navbarText
                      }
                    }
                  }),
                  _c("CNavbarBrand", [_vm._v("CoreuiVue")]),
                  _c(
                    "CCollapse",
                    { attrs: { show: _vm.navbarText, navbar: "" } },
                    [
                      _c(
                        "CNavbarNav",
                        [_c("CNavbarText", [_vm._v("Navbar text")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Navbar ")]),
              _c("small", [_vm._v("dropdown")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { expandable: "sm", color: "primary" } },
                [
                  _c("CToggler", {
                    attrs: { inNavbar: "" },
                    on: {
                      click: function($event) {
                        _vm.navbarDropdown = !_vm.navbarDropdown
                      }
                    }
                  }),
                  _c(
                    "CCollapse",
                    { attrs: { show: _vm.navbarDropdown, navbar: "" } },
                    [
                      _c(
                        "CNavbarNav",
                        [
                          _c("CNavItem", { attrs: { href: "#" } }, [
                            _vm._v("Home")
                          ]),
                          _c("CNavItem", { attrs: { href: "#" } }, [
                            _vm._v("Link")
                          ]),
                          _c(
                            "CDropdown",
                            {
                              attrs: {
                                "toggler-text": "Lang",
                                placement: "bottom-end",
                                "in-nav": ""
                              }
                            },
                            [
                              _c("CDropdownItem", [_vm._v("EN")]),
                              _c("CDropdownItem", [_vm._v("ES")]),
                              _c("CDropdownItem", [_vm._v("RU")]),
                              _c("CDropdownItem", [_vm._v("FA")])
                            ],
                            1
                          ),
                          _c(
                            "CDropdown",
                            {
                              attrs: {
                                "toggler-text": "User",
                                placement: "bottom-end",
                                "in-nav": ""
                              }
                            },
                            [
                              _c("CDropdownItem", [_vm._v("Account")]),
                              _c("CDropdownItem", [_vm._v("Settings")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Navbar ")]),
              _c("small", [_vm._v("form")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { light: "", color: "light" } },
                [
                  _c(
                    "CForm",
                    { attrs: { inline: "" } },
                    [
                      _c("CInput", {
                        staticClass: "mr-sm-2",
                        attrs: { placeholder: "Search", size: "sm" }
                      }),
                      _c(
                        "CButton",
                        {
                          staticClass: "my-2 my-sm-0",
                          attrs: { color: "outline-success", type: "submit" }
                        },
                        [_vm._v("Search")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            [
              _c("CIcon", { attrs: { name: "cil-justify-center" } }),
              _vm._v(" "),
              _c("strong", [_vm._v(" Navbar ")]),
              _c("small", [_vm._v("input group")])
            ],
            1
          ),
          _c(
            "CCardBody",
            [
              _c(
                "CNavbar",
                { attrs: { light: "", color: "light" } },
                [
                  _c(
                    "CForm",
                    { attrs: { inline: "" } },
                    [
                      _c("CInput", {
                        staticClass: "mr-sm-2",
                        attrs: { placeholder: "Username" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }